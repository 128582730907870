import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d2885f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hotels-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_HotelSearchBar = _resolveComponent("HotelSearchBar")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgPriceRange = _resolveComponent("AgPriceRange")!
  const _component_AgFilterDropdown = _resolveComponent("AgFilterDropdown")!
  const _component_AgCheckbox = _resolveComponent("AgCheckbox")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_AgFlightChip = _resolveComponent("AgFlightChip")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_MHotelSearchLoader = _resolveComponent("MHotelSearchLoader")!
  const _component_MHotelSearchListItem = _resolveComponent("MHotelSearchListItem")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!

  return (_ctx.isPropertiesFetching)
    ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_ag_heading, {
          variant: "h2",
          title: "Search Results"
        }),
        _createVNode(_component_HotelSearchBar),
        _createVNode(_component_AgCard, { class: "flightFilterWrap" }, {
          default: _withCtx(() => [
            _createVNode(_component_AgDiv, { class: "head" }, {
              default: _withCtx(() => [
                _createTextVNode(" Filter")
              ]),
              _: 1
            }),
            _createVNode(_component_AgDiv, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_AgFilterDropdown, {
                  "test-id": "",
                  label: "Price Range"
                }, {
                  Items: _withCtx(() => [
                    _createVNode(_component_AgPriceRange, {
                      "onUpdate:rangeSliderValue": _ctx.handleUpdateRange,
                      min: _ctx.minPriceRange,
                      max: _ctx.maxPriceRange,
                      "thumb-size": 20,
                      "thumb-label": "always",
                      "test-id": ""
                    }, null, 8, ["onUpdate:rangeSliderValue", "min", "max"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgFilterDropdown, {
                  "test-id": "",
                  label: "Property Rating",
                  onClick: _cache[2] || (_cache[2] = (e) => e.stopPropagation())
                }, {
                  Items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratingFilter, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AgCheckbox, {
                        onClick: _cache[0] || (_cache[0] = (e) => e.stopPropagation()),
                        modelValue: _ctx.localSelectedFilters.propertyRating,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localSelectedFilters.propertyRating) = $event)),
                        key: index,
                        value: item.value,
                        label: item.label,
                        "test-id": ""
                      }, null, 8, ["modelValue", "value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgFilterDropdown, {
                  "test-id": "",
                  label: "Supplier"
                }, {
                  Items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supplierFilter, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AgCheckbox, {
                        onClick: _cache[3] || (_cache[3] = (e) => e.stopPropagation()),
                        modelValue: _ctx.localSelectedFilters.supplier,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localSelectedFilters.supplier) = $event)),
                        key: index,
                        value: item.value,
                        label: item.label
                      }, null, 8, ["modelValue", "value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgFilterDropdown, {
                  "test-id": "",
                  label: "Booking Policy"
                }, {
                  Items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refundableFilters, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AgCheckbox, {
                        onClick: _cache[5] || (_cache[5] = (e) => e.stopPropagation()),
                        modelValue: _ctx.localSelectedFilters.bookingPolicy,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localSelectedFilters.bookingPolicy) = $event)),
                        key: index,
                        value: item.value,
                        label: item.label,
                        "test-id": ""
                      }, null, 8, ["modelValue", "value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_AgRow, { "test-id": "" }, {
          default: _withCtx(() => [
            _createVNode(_component_AgColumn, {
              "test-id": "",
              sm: "8",
              md: "8",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AgDiv, {
                  "test-id": "",
                  class: "d-flex margin_bottom_10",
                  style: {"flex-wrap":"wrap"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedRatingFilter, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AgFlightChip, {
                        onClick: ($event: any) => (_ctx.handleRemoveChip(index, 'propertyRating')),
                        key: index,
                        value: item,
                        "test-id": ""
                      }, null, 8, ["onClick", "value"]))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedBookingPolicyFilter, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AgFlightChip, {
                        onClick: ($event: any) => (_ctx.handleRemoveChip(index, 'bookingPolicy')),
                        key: index,
                        value: item,
                        "test-id": ""
                      }, null, 8, ["onClick", "value"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.filteredProperties.length > 4)
          ? (_openBlock(), _createBlock(_Transition, {
              key: 0,
              name: "htfade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_MHotelSearchLoader, { message: _ctx.loaderMessage }, null, 8, ["message"]), [
                  [_vShow, _ctx.isLoadingMore && _ctx.hasNext]
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProperties, (item, index) => {
            return (_openBlock(), _createBlock(_component_MHotelSearchListItem, {
              key: index,
              id: `${item.property_id}-${index}`,
              class: "hotel-search-list-item",
              thumbnail: _ctx.formatUrl(item.main_image_url),
              title: item.property_name,
              address: 
            _ctx.formatAddress(
              item.address_line_1,
              item.city_name,
              item.country_name
            )
          ,
              "prepayment-text": _ctx.getPrepaymentText(item.issue_now_pay_later),
              "refund-type": _ctx.formatRefundable(item.non_refundable),
              "starting-from-text": "Starting From",
              price: 
            _ctx.formatPrice(item.gross_price.value, item.gross_price.currency)
          ,
              "days-info": _ctx.getNights,
              rating: _ctx.formatRating(item.rating),
              "traveler-info": _ctx.getTravelersCount,
              "supplier-name": item.supplier,
              "onOn:click": ($event: any) => (_ctx.getPropertyDetailRouteHandler(item.property_id))
            }, null, 8, ["id", "thumbnail", "title", "address", "prepayment-text", "refund-type", "price", "days-info", "rating", "traveler-info", "supplier-name", "onOn:click"]))
          }), 128))
        ]),
        _createVNode(_Transition, {
          name: "htfade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_MHotelSearchLoader, { message: _ctx.loaderMessage }, null, 8, ["message"]), [
              [_vShow, _ctx.isLoadingMore && _ctx.hasNext]
            ])
          ]),
          _: 1
        }),
        (_ctx.showNoResult())
          ? (_openBlock(), _createBlock(_component_AgNotFound, {
              key: 1,
              "test-id": "",
              heading: "No Results Found",
              description: "Please Try Modify Your Filters OR Try Again"
            }))
          : _createCommentVNode("", true)
      ], 64))
}